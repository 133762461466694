import React from 'react';
import { Link } from 'gatsby';
import FluidImage from '../components/fluid-image.js';

const StoryPage = ({ pageContext }) => {
  console.log({ pageContext });
  const {
    title,
    pageNumber,
    prevPage,
    nextPage,
    image,
    titleBanner
  } = pageContext;

  let daImage = {imageFile: image};

  let Header = () => {
    if (titleBanner) {
      return (<FluidImage image={titleBanner} className='w-1/2 m-auto my-8'/>);
    } else {
      return (<h1 className='text-center text-3xl'>{title}</h1>)
    }
  };


  return (
    <section
      className='w-full'
      fullWidth={false} >
      <Header />
      <nav className="flex justify-between w-full p-6">
        {prevPage ? <Link to={prevPage}>Previous</Link> : <em className="opacity-0">previous</em>}
        <h2>Page {pageNumber}</h2>
        {nextPage ? <Link to={nextPage}>Next</Link> : <em className="opacity-0">next</em>}
      </nav>
      <article className='items-center'>
    <FluidImage image={daImage} className='w-full'style={{maxWidth: '900px'}}/>
      </article>
      <nav className="flex justify-between w-full p-6">
        {prevPage ? <Link to={prevPage}>Previous</Link> : <em className="opacity-0">previous</em>}
        <h2>Page {pageNumber}</h2>
          {nextPage
           ? <Link to={nextPage}>Next</Link>
           : <em className="opacity-0">next</em>}
      </nav>
    </section>
  );
};

export default StoryPage;
